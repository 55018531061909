.swiper-slide {
  padding-bottom: 30px !important;
  margin-bottom: unset !important;
}

.animated_button {
  position: relative;
  margin: auto;
  padding: 12px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize !important;
}

.animated_button:before {
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
  display: block;
  border-radius: 50px;
  background: #f0e9d2ba;
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
}

.animated_button .button_name {
  position: relative;
  padding-left: 5px;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #678983;
  text-transform: capitalize !important;
}

.animated_button svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #678983;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.animated_button:hover:before {
  width: 100%;
  background: #f0e9d2ba;
}

.animated_button:hover svg {
  transform: translateX(0);
}

.animated_button:active {
  transform: scale(0.95);
}

.navlink .active {
  border-bottom: 3px solid #678983;
}
