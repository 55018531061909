.order_banner {
  background-image: url("../../images/ordersmaybe.jpg");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ordercard_banner {
  background-image: url("../../images/24857585.jpg");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
