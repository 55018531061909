.input_fields input,
.input_fields textarea {
  border: none;
  background-color: #f4f6f9;
}

.boxShadow {
  box-shadow: 10px 30px 20px 0px
    rgba(
      20.999999999999996,
      20.999999999999996,
      20.999999999999996,
      0.10980392156862745
    );
}

.boxShadow_about {
  box-shadow: 10px 30px 20px 20px
    rgba(
      20.999999999999996,
      20.999999999999996,
      20.999999999999996,
      0.10980392156862745
    );
}

.custom-navigation {
  position: absolute;
  width: 100%;
  top: 50%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 10;
}

.custom-prev-button,
.custom-next-button {
  background-color: #333;
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.custom-prev-button:hover,
.custom-next-button:hover {
  background-color: #555;
}
