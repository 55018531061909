.about_banner {
  background-image: url("../../images/slide1.jpg");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutcard_banner {
  background-image: url("../../images/20125856_6221480.jpg");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.content_border {
  border-style: solid;
  border-color: #678983;
  border-width: 0 0 0 4px;
  background-color: #e6ddc43d;
  border-radius: 0 10px 10px 0;
}
