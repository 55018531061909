.services_banner {
  background-image: url("../../images/slide3.jpg");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.service_cards {
  background-image: url("../../images/36178556_8389841.jpg");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
