* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Signika Negative", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.athiti-semibold {
  font-family: "Athiti", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.elementor-divider-separator {
  display: flex;
  width: 80px;
  margin: 0 auto 5px auto;
  margin-left: 0;
  background-color: #678983;
  height: 3px;
}

.heading {
  color: #678983;
}

.title {
  color: #181d31;
}

.focus_bg {
  background-color: #e6ddc4;
}

.content {
  color: #6b728e;
}

.icon_bg {
  background-color: #678983;
}

.header_bg {
  background-color: #181d31;
}

.focus_text {
  color: #f0e9d2;
}

.swiper_head .swiper-pagination-bullet-active {
  background: #678983;
}

.speedDail .css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab,
.speedDail .css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab:hover,
.speedDail .css-r9uhzn,
.speedDail .css-r9uhzn:hover {
  background-color: #678983;
}

::selection {
  color: #fff;
  background-color: #678983;
}
