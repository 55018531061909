.card-list {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-between; */
  /* max-width: 1000px;
  margin: 0 auto; */
}

.card-item {
  position: relative;
  /* margin-bottom: 20px; */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
  /* height: 120px; */
}

.card-header {
  position: relative;
  padding: 10px;
  font-weight: bold;
  border-radius: 8px 8px 0 0;
}

.card-header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-bottom: 15px solid white;
}

.card-body {
  /* background-color: azure; */
  /* bord: 1px solid #666; */
}
/*
.card-body p {
  margin: 0;
  color: #666;
} */
